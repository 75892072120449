import React, { useEffect, useState } from 'react';

// Constsnts
import { ROUTES } from '../constants/general';

// Images
import lightTextLogo from '../assets/images/site-logo-white.svg';
import darkTextLogo from '../assets/images/site-logo-black.svg';

// Utility Packages
import { Link } from 'react-router-dom';

function Header({ isLightBackground = false, isMenuVisible = true }) {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scroll ? 'site-header sticky-header' : 'site-header'}>
      <div className="header-wrapper">
        <div className="logo-wrapper">
          <a className="white-logo" href="/">
            <img src={isLightBackground ? darkTextLogo : lightTextLogo} alt="logo" />
          </a>
        </div>
    
          <div className="navigation-wrapper">
            <nav className="main-nav">
              <ul className="menu-navigation">
              {isMenuVisible && (
                <li>
                  <Link title="Contact" to={ROUTES.CONTACT}>
                    contact
                  </Link>
                </li>
                    )}
                <li className="contact-wrapper" style={{ margin: 0 }}>
                  <Link title="Login / Sign Up" className="btn yellow-btn" to={ROUTES.LOGIN}>
                    Login / Sign Up
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
    
      </div>
    </header>
  );
}

export default Header;
