// Base URL
const BASE_URL = process.env.REACT_APP_BASE_URL;

// ******  AUTH ****** //
export const LOGIN_API = `${BASE_URL}/auth/signin`;
export const REGISTER_API = `${BASE_URL}/auth/signup`;
export const FORGOT_PASSWORD_API = `${BASE_URL}/auth/forgot-password`;
export const RESET_PASSWORD_API = `${BASE_URL}/auth/reset-password`;

// ******  USER ****** //
export const PROFILE_API = `${BASE_URL}/user/profile`;
export const PROFILE_IMAGE_API = `${BASE_URL}/user/profile-image`;
export const USER_API = `${BASE_URL}/user`;
export const CHANGE_PASSWORD_API = `${BASE_URL}/user/change-password`;
export const INTERVIEW_ROUND_API = `${BASE_URL}/interview-round`;
export const TECHNOLOGY_API = `${BASE_URL}/technology`;
export const JOB_API = `${BASE_URL}/jobs`;
export const CONTACTS_API = `${BASE_URL}/contacts`;
