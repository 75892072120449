import React, { useState } from 'react';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Asteric from '../../components/Asteric';
import Label from '../../components/Label';

// Constants
import { DESCRIPTION_CHAR_MIN_LIMIT, LABELS } from '../../constants/general';
import { contactUsValidationSchema } from '../../constants/schemas';

// Utility Functions
import { sendContactRequest } from '../../middleware/contact';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';

// Utility Packages
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';

const Contact = () => {
  // Store
  const toastRef = useSelector((state) => state.notifications.toastReff);

  // State
  const [onFocus, setOnFocus] = useState(false);
  const [skillInput, setSkillInput] = useState('');
  const [skillSuggestion, setSkillSuggestion] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      companyName: '',
      skills: '',
      description: ''
    },
    validationSchema: contactUsValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values);
      sendContactRequest(
        {
          ...values,
          mobile: values?.mobile.toString(),
          skills: values.skills.map((op) => op.value).join(',')
        },
        toastRef,
        resetForm,
        setSkillSuggestion
      );
    }
  });

  const handleOnFocus = () => {
    setOnFocus(true);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
  };

  const handleSkillSuggestion = (e) => {
    const options = skillSuggestion.concat(e);
    const newOptions = options.filter(
      (item, index) => options.findIndex((obj) => obj.value === item.value) === index
    );
    setSkillSuggestion(newOptions);
  };

  return (
    <>
      <div className="contact-page-container">
        <Header isMenuVisible={false} />
        <div className="contact-page">
          <div className="authentication-wrapper contact-page-contant-container">
            <div className="authentication-inner">
              <div className="left-col">
                <div className="heading-block">
                  <h1>Contact us</h1>
                  <p>Please send us your requirements and we'll get back to you at the earliest.</p>
                </div>
                <ul className="call-mail-block">
                  <li>
                    <span>Inquiry: </span>{' '}
                    <a href="mailto:info@tatvasoft.com" title="Mail Us">
                      info@tatvasoft.com
                    </a>
                  </li>
                  <li>
                    <span>Work with us: </span>{' '}
                    <a href="mailto:career@tatvasoft.com" title="Mail Us">
                      career@tatvasoft.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="right-col">
                <div className="login-container">
                  <h2>Get in Touch</h2>
                  <form className="p-fluid">
                    <div className="field">
                      <Label htmlFor="name" text={LABELS.INPUT.NAME} isBold onFocus={onFocus} />
                      <Asteric />
                      <InputText
                        id="name"
                        name="name"
                        placeholder={LABELS.INPUT.NAME}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'name')
                        })}
                      />
                      {getFormErrorMessage(formik, 'name')}
                    </div>
                    <div className="field">
                      <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold onFocus={onFocus} />
                      <Asteric />
                      <InputText
                        id="email"
                        name="email"
                        placeholder={LABELS.INPUT.EMAIL}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'email')
                        })}
                      />
                      {getFormErrorMessage(formik, 'email')}
                    </div>
                    <div className="field">
                      <Label htmlFor="mobile" text={LABELS.INPUT.PHONE} isBold onFocus={onFocus} />
                      <Asteric />
                      <InputNumber
                        id="mobile"
                        name="mobile"
                        placeholder={LABELS.INPUT.PHONE}
                        value={formik.values.mobile}
                        onValueChange={(e) => formik.setFieldValue('mobile', e.value)}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'mobile')
                        })}
                        mode="decimal"
                        useGrouping={false}
                        maxLength={10}
                      />
                      {getFormErrorMessage(formik, 'mobile')}
                    </div>
                    <div className="field">
                      <Label
                        htmlFor="companyName"
                        text={LABELS.INPUT.COMPANY_NAME}
                        isBold
                        onFocus={onFocus}
                      />
                      <Asteric />
                      <InputText
                        id="companyName"
                        name="companyName"
                        placeholder={LABELS.INPUT.COMPANY_NAME}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'companyName')
                        })}
                      />
                      {getFormErrorMessage(formik, 'companyName')}
                    </div>
                    <div className="field autocomplete">
                      <Label htmlFor="skills" text={LABELS.INPUT.SKILLS} isBold />
                      <Asteric />
                      <div className="custom-form-group">
                        <CreatableSelect
                          isMulti
                          hideSelectedOptions={false}
                          id="skills"
                          name="skills"
                          value={formik.values.skills}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            const normalizedOptions = e.map((item) => ({
                              ...item,
                              value: item.value.toLowerCase(),
                              label: item.label
                            }));
                            formik.setFieldValue('skills', normalizedOptions);
                            handleSkillSuggestion(e);
                          }}
                          options={skillSuggestion.map((item) => ({
                            ...item,
                            value: item.value.toLowerCase()
                          }))}
                          placeholder={LABELS.INPUT.SKILLS}
                          className={classNames({
                            'p-invalid': isFormFieldValid(formik, 'skills')
                          })}
                          classNamePrefix="custom-create-select"
                          inputValue={skillInput}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              e.stopPropagation();
                              if (e.target.value) {
                                const newOption = {
                                  label: e.target.value,
                                  value: e.target.value.toLowerCase()
                                };
                                const existingOptions = formik.values.skills || [];
                                const isDuplicate = existingOptions.some(
                                  (option) => option.value === newOption.value
                                );
                                if (!isDuplicate) {
                                  formik.setFieldValue('skills', [...existingOptions, newOption]);
                                  handleSkillSuggestion([newOption]);
                                }
                                setSkillInput('');
                              }
                            }
                          }}
                          onInputChange={(inputValue) => {
                            setSkillInput(inputValue);
                          }}
                        />
                        {getFormErrorMessage(formik, 'skills')}
                      </div>
                    </div>
                    <div className="field">
                      <Label htmlFor="description" text={LABELS.INPUT.DESCRIPTION} isBold />
                      <Asteric />
                      <div className="custom-form-group">
                        <InputTextarea
                          placeholder={LABELS.INPUT.DESCRIPTION}
                          labelText={LABELS.INPUT.DESCRIPTION}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          errorMessage={
                            formik.touched['description'] ? formik.errors['description'] : ''
                          }
                          className={classNames({
                            'p-invalid': isFormFieldValid(formik, 'description')
                          })}
                          rows={5}
                          cols={30}
                          id="description"
                          name="description"
                        />
                        <div className="text-area-flex-container">
                          <div className="text-area-formik-error">
                            {getFormErrorMessage(formik, 'description')}
                          </div>
                          <div paragraph align="right" className="text-area-limit-text-setter">
                            {!!formik.values.description?.length &&
                              formik.values.description?.length < DESCRIPTION_CHAR_MIN_LIMIT && (
                                <>
                                  <span
                                    className={classNames({
                                      'counter-limit-exceeded':
                                        formik.values.description?.length <
                                          DESCRIPTION_CHAR_MIN_LIMIT &&
                                        formik.values.description?.length !== 0
                                    })}>
                                    {formik.values.description?.length}
                                  </span>
                                  <span className="text-area-limit-text-setter">
                                    /{DESCRIPTION_CHAR_MIN_LIMIT}
                                  </span>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button className="w-full" onClick={formik.handleSubmit}>
                      {LABELS.BUTTON.SUBMIT}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
