// Constants
import { CONTACTS_API } from '../constants/configs';

// Utility Functions
import { API_REQUEST } from '../constants/general';
import { Actions } from '../redux/actions';
import API from '../utils/api';
import { handleError, handleSuccess } from '../utils/toast';
import { dispatch } from '../utils/store';

export const sendContactRequest = (data, toastRef, resetForm, setSkillSuggestion) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.post, CONTACTS_API, data)
    .then((res) => {
      handleSuccess(toastRef, res);
      resetForm({ values: '' });
      setSkillSuggestion([]);
      dispatch(Actions.User.SetLoading, false);
    })
    .catch((error) => {
      handleError(toastRef, error);
      console.log(error);
      dispatch(Actions.User.SetLoading, false);
    });
};
