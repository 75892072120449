import React, { useState } from 'react';

// APIs
import { REGISTER_API } from '../../constants/configs';

// Constants
import { API_REQUEST, LABELS, ROUTES } from '../../constants/general';
import { registerValidationSchema } from '../../constants/schemas';

// Components
import Label from '../../components/Label';
import Asteric from '../../components/Asteric';
import { classNames } from 'primereact/utils';
import CustomPassword from '../../components/CustomPassword';

// Images
import logoImg from '../../assets/images/site-logo-black.svg';
import siteLogoTatva from '../../assets/images/site-logo-tatva.svg';

// Utility Functions
import { handleError, handleSuccess } from '../../utils/toast';
import API from '../../utils/api';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';

// Utility Packages
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';
import { InputNumber } from 'primereact/inputnumber';

export default function Register() {
  const navigate = useNavigate();

  // State
  const [onFocus, setOnFocus] = useState(false);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // Handle form values
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      // gender: 1,
      password: '',
      confirmPassword: ''
    },
    validationSchema: registerValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values);
      userRegister({ ...values, mobile: values?.mobile.toString() }, resetForm);
    }
  });

  const userRegister = (userData, resetForm) => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.post, REGISTER_API, userData)
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
        resetForm({ values: '' });
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const handleOnFocus = () => {
    setOnFocus(true);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
  };

  return (
    <div className="authentication-wrapper singup-page">
      <div className="authentication-inner">
        <div className="login-container">
          <div className="site-logo">
            <Link to={ROUTES.HOME} title="Interview Management System">
              <img src={logoImg} alt="" />
            </Link>
          </div>
          <h2>{LABELS.WELCOME}</h2>
          <form className="p-fluid">
            <div className="field">
              <Label htmlFor="firstName" text={LABELS.INPUT.FIRSTNAME} isBold onFocus={onFocus} />
              <Asteric />
              <InputText
                id="firstName"
                name="firstName"
                placeholder={LABELS.INPUT.FIRSTNAME}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className={classNames({
                  'p-invalid': isFormFieldValid(formik, 'firstName')
                })}
              />
              {getFormErrorMessage(formik, 'firstName')}
            </div>
            <div className="field">
              <Label htmlFor="lastName" text={LABELS.INPUT.LASTNAME} isBold onFocus={onFocus} />
              <Asteric />
              <InputText
                id="lastName"
                name="lastName"
                placeholder={LABELS.INPUT.LASTNAME}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className={classNames({
                  'p-invalid': isFormFieldValid(formik, 'lastName')
                })}
              />
              {getFormErrorMessage(formik, 'lastName')}
            </div>
            <div className="field">
              <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold onFocus={onFocus} />
              <Asteric />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText
                  id="email"
                  name="email"
                  placeholder={LABELS.INPUT.EMAIL}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'email')
                  })}
                />
              </span>
              {getFormErrorMessage(formik, 'email')}
            </div>
            <div className="field">
              <Label htmlFor="mobile" text={LABELS.INPUT.PHONE} isBold onFocus={onFocus} />
              <Asteric />
              <InputNumber
                id="mobile"
                name="mobile"
                placeholder={LABELS.INPUT.PHONE}
                value={formik.values.mobile}
                onValueChange={(e) => formik.setFieldValue('mobile', e.value)}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className={classNames({
                  'p-invalid': isFormFieldValid(formik, 'mobile')
                })}
                mode="decimal"
                useGrouping={false}
                maxLength={10}
              />
              {getFormErrorMessage(formik, 'mobile')}
            </div>
            {/* <div className="field">
              <Label htmlFor="gender" text={LABELS.INPUT.GENDER} isBold />
              <Asteric />
              <div className="radio-list-wrapper">
                <div className="form-group-outer-radiobutton">
                  <RadioButton
                    name="male"
                    value={Gender.Male}
                    onChange={(e) => formik.setFieldValue('gender', e.value)}
                    checked={formik.values.gender === Gender.Male}
                  />
                  <label htmlFor="male" className="label">
                    {LABELS.INPUT.MALE}
                  </label>
                </div>
                <div className="form-group-outer-radiobutton">
                  <RadioButton
                    name="female"
                    value={Gender.Female}
                    onChange={(e) => {
                      formik.setFieldValue('gender', e.value);
                    }}
                    checked={formik.values.gender === Gender.Female}
                  />
                  <label htmlFor="female" className="label">
                    {LABELS.INPUT.FEMALE}
                  </label>
                </div>
              </div>
            </div> */}
            <div className="field pwd">
              <CustomPassword
                htmlFor="password"
                text={LABELS.INPUT.CREATE_PASSWORD}
                ismandatory
                isbold
                id="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({ 'p-invalid': isFormFieldValid(formik, 'password') })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'password')}
            </div>
            <div className="field pwd">
              <CustomPassword
                htmlFor="confirmPassword"
                text={LABELS.INPUT.CONFIRM_PASSWORD}
                ismandatory
                isbold
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({ 'p-invalid': isFormFieldValid(formik, 'confirmPassword') })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'confirmPassword')}
            </div>
            <Button className="w-full" onClick={formik.handleSubmit}>
              {LABELS.BUTTON.SIGN_UP}
            </Button>
            <div className="link-wrapper center">
              <span>Already have an account?</span>
              <Link to={ROUTES.LOGIN}>{LABELS.BUTTON.SIGN_IN}</Link>
            </div>
          </form>
          <div className="tatva-logo-wrap">
            <span>{LABELS.POWERED_BY}</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
          </div>
        </div>
      </div>
    </div>
  );
}
