// Constants
import { API_REQUEST, barChartoprions, LABELS } from '../../constants/general';
import API from '../../utils/api';
import { USER_API } from '../../constants/configs';

// images
import fileIcon from '../../assets/images/dashboard-file-img.svg';
import profileIcon from '../../assets/images/dashboard-profile.svg';
import apppliedJobIcon from '../../assets/images/dashboard-applied-jobs.svg';
import interviewIcon from '../../assets/images/dashboard-interview.svg';

// utility packages
import { Chart } from 'primereact/chart';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// utility Functions
import { handleError, handleSuccess } from '../../utils/toast';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

function Dashboard() {
  const documentStyle = getComputedStyle(document.documentElement);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // State
  const [jobOpenings, setJobOpenings] = useState(0);
  const [appliedJobs, setAppliedJobs] = useState(0);
  const [upcomingInterviews, setUpcomingInterviews] = useState(0);
  const [barChartData, setBarChartData] = useState({});

  useEffect(() => getDashboardData(), []);

  const getDashboardData = () => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.get, `${USER_API}/dashboard`)
      .then((res) => {
        setJobOpenings(res?.data?.data?.data?.totalJobsCount || 0);
        setAppliedJobs(res?.data?.data?.data?.jobApplicationsCount || 0);
        setUpcomingInterviews(res?.data?.data?.data?.upcomingInterviews || 0);
        if (res?.data?.data?.data?.jobs?.length) {
          const expLabels = [];
          const expValues = [];
          res?.data?.data?.data?.jobs.forEach((job) => {
            expLabels.push(job.label);
            expValues.push(job.job_count);
          });
          setBarChartData({
            labels: expLabels,
            datasets: [
              {
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                data: expValues
              }
            ]
          });
        }
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  return (
    <div className="dashboard-page-wrapper">
      <div className="dashboard-grid">
        <div className="dashboard-grid-block">
          <div className="dashboard-grid-inner-block">
            <div className="title-wrapper">
              <p>{LABELS.Total_Job_Openings}</p>
              <em>
                <img src={fileIcon} alt="file-icon" />
              </em>
            </div>
            <span>{jobOpenings}</span>
          </div>
        </div>
        <div className="dashboard-grid-block">
          <div className="dashboard-grid-inner-block purple-card">
            <div className="title-wrapper">
              <p>{LABELS.Total_Applied_Jobs}</p>
              <em>
                <img src={apppliedJobIcon} alt="applied-job-icon" />
              </em>
            </div>
            <span>{appliedJobs}</span>
          </div>
        </div>
        <div className="dashboard-grid-block">
          <div className="dashboard-grid-inner-block yellow-card">
            <div className="title-wrapper">
              <p>{LABELS.Upcoming_Interviews}</p>
              <em>
                <img src={interviewIcon} alt="interview-icon" />
              </em>
            </div>
            <span>{upcomingInterviews}</span>
          </div>
        </div>
      </div>
      <div className="dashboard-grid dashboard-chart-grid">
        {/* <div className="dashboard-grid-block">
          <div className="dashboard-chart-grid-inner">
            <div className="title">
              <em>
                <img src={profileIcon} alt="profile-icon" />
              </em>
              <p>Profile Completion</p>
            </div>
            <div className="char-wrapper">
              <Chart
                type="doughnut"
                data={chartData}
                options={{ cutout: '60%' }}
                className="w-full md:w-30rem"
              />
            </div>
          </div>
        </div>
        <div className="dashboard-grid-block">
          <div className="dashboard-chart-grid-inner">
            <div className="title">
              <em>
                <img src={apppliedJobIcon} alt="applied-job-icon" />
              </em>
              <p>Applied Jobs by Status</p>
            </div>
            <div className="char-wrapper">
              <Chart
                type="doughnut"
                data={chartData}
                options={{ cutout: '60%' }}
                className="w-full md:w-30rem"
              />
            </div>
          </div>
        </div> */}
        <div className="dashboard-grid-block has-full-width">
          <div className="dashboard-chart-grid-inner">
            <div className="title">
              <em>
                <img src={fileIcon} alt="file-icon" />
              </em>
              <p>{LABELS.Current_Job_Opening_By_Experience}</p>
            </div>
            <div className="char-wrapper">
              <Chart type="bar" data={barChartData} options={barChartoprions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
